import React from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "@assets/svgs/logo.svg"

const Footer = () => {
  return (
    <footer className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="flex flex-col-reverse items-center justify-between pt-5 pb-10 border-t lg:flex-row">
        <div className="sm:col-span-2">
          <Link
            to="/"
            aria-label="Company"
            title="Company"
            className="inline-flex items-center mr-8"
          >
            <Logo className="w-auto h-8" />
          </Link>
        </div>
        <p className="mb-4 text-sm text-gray-600 lg:mb-0">
          © Copyright 2021 from people. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 text-center lg:text-left lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <Link
              to="/contact/#faq"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-cyan-800"
            >
              F.A.Q
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-cyan-800"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
