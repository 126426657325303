import React from "react"
import Navbar from "@components/navbar"
import Footer from "@components/footer"
import SEO from "@components/seo"

interface Props {
  title: string
  description: string
}

const PageTemplate: React.FC<Props> = ({ title, description, children }) => {
  return (
    <>
      <SEO title={title} description={description} />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default PageTemplate
